export const landingPageEventCategories = {
    searchSection: {
        SEARCH_BOX: 'Suche',
        BREADCRUMB: 'Main',
    },
    headerSection: {
        RENTAL: 'region',
        WISHLIST: 'Profile',
    },
    newsLetterSection: {
        REGISTER: 'Social',
    },
    listingCardSection: {
        TOP_INSERAT: 'Suche',
        WISHLIST_POP_UP: 'Wishlist',
    },
    stickyFooterSection: {
        STICKY_FOOTER: 'sticky_footer',
    },
    topRecommendationsSection: {
        CTA_VIEW_RECOMMENDATIONS: 'rlp_all_accommodation',
    },
}
export const CommonPageEventCategories = {
    searchSection: {
        SEARCH_BOX: 'searchbox',
    },
}
export const searchResultListingsPageEventCategories = {
    listViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    mapViewPageSpecific: {
        mapSection: {
            MAP_SEARCH: 'suche@map_v2',
        },
    },
    tileViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    filterSection: {
        FILTER_CLICK: 'resultfilter@',
    },
}

export const eventPageCategories = {
    LANDING_PAGE: 'bk_event_offer@landingpage',
    SEARCH_RESULT_PAGE: 'bk_event_offer@results',
    DETAIL_PAGE: 'bk_event_offer@eventdetailpage',
}
