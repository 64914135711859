import config from './../common/config'
import { TranslationFunction } from '../common/types'
import { format } from '../hooks/date'
import BreadcrumbTransport from '../transports/common/breadcrumb-transport'

/**
 * @deprecated
 *
 * Please do not use this method. Any new code should not use this method and should try to think of a
 * different solution.
 * @param path
 */
export const getBasePath = (path: string): string => {
    return `${config.basePath}${path}`
}

export const getFormattedDate = (date: Date, sameYearDate: boolean): string => {
    return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}
    ${sameYearDate ? '' : date.getFullYear()}`
}

export const historyDateDisplay = (arrival: Date, departure: Date): string =>
    `${format(arrival, arrival.getFullYear() === departure.getFullYear() ? 'dd MMM' : 'dd MMM yyyy')} - ${format(
        departure,
        'dd MMM yyyy',
    )}`

export const getFeatureTextFromKey = (key: string, t: TranslationFunction): string | null => {
    switch (key) {
        case 'wifi':
            return t('featureWifi')
        case 'pets':
            return t('featurePets')
        case 'pets-prohibited':
            return t('featureNoPets')
        case 'pets-unspecified':
            return null
        case 'smoking':
            return t('featureSmoking')
        case 'smoking-prohibited':
            return t('featureSmokeFree')
        case 'smoking-unspecified':
            return null
        case 'washing-machine':
            return t('featureWashingMachine')
        case 'television':
            return t('featureTelevision')
        case 'pool':
            return t('featurePool')
        case 'dishwasher':
            return t('featureDishwasher')
        default:
            return t('featureWifi')
    }
}

export const getOrganizationSchemaDetail = (t: TranslationFunction) => {
    return {
        __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            contactPoint: [
                {
                    '@type': 'ContactPoint',
                    telephone: '+49 (0)421 - 146 29 600 ',
                    contactType: 'customer service',
                },
            ],
            additionalType: 'WebSite',
            url: t('traumBaseUrl'),
            logo: `${t('traumBaseUrl')}/assets/_files/images/logo/tfw-logo-all.svg`,
            name: 'Traum-Ferienwohnungen',
            sameAs: [
                'https://www.facebook.com/TraumFerienwohnungen',
                'https://www.instagram.com/traumferienwohnungen/',
                'https://de.pinterest.com/traumfewo/',
                'https://twitter.com/traumfewo',
                'https://plus.google.com/+Traum-Ferienwohnungen',
            ],
        }),
    }
}

export const getBreadcrumbSchemaDetail = (breadcrumbs: BreadcrumbTransport[]) => {
    const BreadcrumbSchema = breadcrumbs.map((item, index) => {
        return {
            '@type': 'ListItem',
            position: index,
            item: {
                '@id': item.url,
                name: item.title,
            },
        }
    })

    return {
        __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: BreadcrumbSchema,
        }),
    }
}

export const getSearchDate = (date: Date | null) => (date ? format(date, 'yyyy-MM-dd') : '')
