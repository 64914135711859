import React, { ComponentType, forwardRef, HTMLProps, MouseEventHandler, useCallback } from 'react'

interface ExternalLinkProps extends Omit<HTMLProps<HTMLAnchorElement>, 'as'> {
    as?: ComponentType<any> | keyof JSX.IntrinsicElements
    noCrawl?: boolean
    onClick?: MouseEventHandler
    /* Props to be passed to the component that is passed in the `as` prop. */
    [others: string]: any
}

function _goToUrl(url: string, target?: string) {
    if (target && target !== '_self') {
        try {
            const win = window.open(url, target)
            if (win) {
                win.focus()
            } else {
                // Reaching here means the pop-up was blocked.
                // TODO: Show an alert to user to allow pop-ups.
            }
        } catch (error) {
            console.error({ error })
        }
    } else {
        window.location.href = url
    }
}

const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>((props, ref) => {
    const { as, noCrawl, onClick, href, target, autoRedirect, ...rest } = props

    // TODO: Style this anchor. Give it good default styles according to application's theme.
    const Component = as ?? 'a'

    const _autoRedirect = autoRedirect ?? true

    const _handleClick: MouseEventHandler = useCallback(
        event => {
            if (onClick) {
                // If the consumer passed in onClick, call it.
                onClick(event)
            }

            // TODO: If analytics' SDK needs to be called, can call it here.

            if (_autoRedirect && noCrawl && href) {
                _goToUrl(href, target)
            }
        },
        [href, noCrawl, onClick, target],
    )

    if (noCrawl) {
        return <Component ref={ref} onClick={_handleClick} target={target} {...rest} />
    }
    return <Component ref={ref} onClick={_handleClick} href={href} target={target} {...rest} />
})

ExternalLink.displayName = 'ExternalLink'

export default ExternalLink
