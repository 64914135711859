import styled, { AnyStyledComponent } from 'styled-components'

import MapPin from '../../assets/icons/userbar-icons/map-pin.svg'
import Calendar from '../../assets/icons/userbar-icons/calendar.svg'
import Users from '../../assets/icons/userbar-icons/users.svg'
import Cancel from '../../assets/icons/userbar-icons/cancel.svg'
import Back from '../../assets/icons/userbar-icons/back.svg'
import ChevronDown from '../../assets/icons/chevron-down.svg'
import ChevronRight from '../../assets/icons/chevron-right.svg'
import ChevronLeft from '../../assets/icons/chevron-left.svg'
import CircledCrossMini from '../../assets/icons/userbar-icons/circled-cross-mini.svg'
import TickIcon from '../../assets/icons/tick.svg'
import ExternalIcon from '../../assets/icons/external-icon.svg'
import Duration from '../../assets/icons/duration.svg'
import Clock from '../../assets/icons/clock.svg'
import Home from '../../assets/icons/home.svg'
import Buy from '../../assets/icons/buy.svg'
import MiniLogo from '../../assets/logo/tfw-logo-symbol.svg'
import HeartImageIcon from '../../assets/icons/heart.svg'
import HeartOImageIcon from '../../assets/icons/heart-o.svg'

export const LocationPinIcon = styled(MapPin as AnyStyledComponent)`
    /* Provide min width and height so that the element isn't small-sized when present inside a flex container. */
    min-width: ${({ theme }) => theme.layout.xxSmall};
    min-height: ${({ theme }) => theme.layout.xxSmall};
`

export const CalendarIcon = styled(LocationPinIcon as AnyStyledComponent).attrs({ as: Calendar })``
export const GuestsIcon = styled(LocationPinIcon as AnyStyledComponent).attrs({ as: Users })``

export const CancelIcon = styled(Cancel as AnyStyledComponent).attrs({ width: '12px', height: '12px' })`
    min-width: 0.75rem;
    min-height: 0.75rem;
`

export const BackIcon = styled(Back).attrs({ width: '14px', height: '14px' })`
    min-width: 0.875rem;
    min-height: 0.875rem;
`

export const ChevronUpIcon = styled(ChevronDown).attrs({ width: '12px', height: '12px' })`
    min-width: 0.75rem;
    min-height: 0.75rem;
    transform: rotate(180deg);
`

export const ChevronDownIcon = styled(ChevronDown as AnyStyledComponent).attrs({ width: '12px', height: '12px' })`
    min-width: 0.75rem;
    min-height: 0.75rem;
`

export const ChevronRightIcon = styled(ChevronDownIcon).attrs({ as: ChevronRight })``
export const ChevronLeftIcon = styled(ChevronDownIcon).attrs({ as: ChevronLeft })``

export const CircledCrossMiniIcon = styled(CircledCrossMini)`
    min-width: 1rem;
    min-height: 1rem;
`
export const EventTickIcon = styled(TickIcon as AnyStyledComponent)`
    min-width: 0.875rem;
    max-width: 0.875rem;
    height: 0.875rem;
`

export const ExternalPageIcon = styled(ExternalIcon as AnyStyledComponent)`
    /* Provide min width and height so that the element isn't small-sized when present inside a flex container. */
    min-width: ${({ theme }) => theme.layout.xxSmall};
    min-height: ${({ theme }) => theme.layout.xxSmall};
`

export const DurationIcon = styled(LocationPinIcon as AnyStyledComponent).attrs({ as: Duration })``
export const ClockIcon = styled(LocationPinIcon as AnyStyledComponent).attrs({ as: Clock })``
export const HomeIcon = styled(LocationPinIcon as AnyStyledComponent).attrs({ as: Home })``
export const BuyIcon = styled(LocationPinIcon as AnyStyledComponent).attrs({ as: Buy })``
export const MiniLogoIcon = styled(CancelIcon as AnyStyledComponent).attrs({ as: MiniLogo })`
    min-width: 1.25rem;
    min-height: 100%;
`

export const HeartIcon = styled(HeartImageIcon as AnyStyledComponent)`
    width: 1rem;
    min-height: 1rem;
`

export const HeartOIcon = styled(HeartIcon as AnyStyledComponent).attrs({ as: HeartOImageIcon })``
