import { DataLayerSitePayload, EventPayload } from './types'
import { transformEventPayload } from './utils'

class AnalyticsService {
    // Pushes tracking options object to GTM dataLayer
    private static track(options: any) {
        if (typeof window !== 'undefined' && window.dataLayer?.push && options) {
            window.dataLayer.push(options)
        }
    }

    public static trackEvent(eventPayload: EventPayload, attachEvent = true) {
        if (attachEvent) {
            // must be auto trigger to forward event to correct GA tracking ID
            eventPayload.event = eventPayload.event ?? 'autoTrigger'
        }

        const event = transformEventPayload(eventPayload)

        AnalyticsService.track(event)
    }

    public static trackClickEvent(category: string, label: string) {
        AnalyticsService.trackEvent({ action: 'click', category, label })
    }

    public static trackSwipeEvent(category: string, label: string) {
        AnalyticsService.trackEvent({ action: 'swipe', category, label })
    }

    public static trackSiteName(dataLayerSitePayload: DataLayerSitePayload) {
        AnalyticsService.trackEvent(dataLayerSitePayload, false)
    }
}

export default AnalyticsService
