export const landingPageEventLabels = {
    headerSection: {
        RENTAL: 'Rental button',
        WISHLIST: 'Wishlist button',
    },
    newsLetterSection: {
        REGISTER: 'Newsletter register button',
    },
    listingCardSection: {
        WISHLIST_POP_UP: 'Wishlist',
    },
}

export const CommonPageEventLabels = {
    searchSection: {
        AUTO_SUGGEST: 'click_destination_autosuggest',
    },
}
export const searchResultListingsPageEventLabels = {
    listViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    mapViewPageSpecific: {
        mapSection: {
            DRAG_ENABLED: 'mapDragged_enabled',
            DRAG_DISABLED: 'mapDragged_disabled',
            ZOOM_ENABLED: 'mapZoomed_enabled_',
            ZOOM_DISABLED: 'mapZoomed_disabled_',
            MARKER_CLICK: 'click_marker',
            MAP_TYPE: 'click_maptype_',
            AGGREGATION_MARKER_CLICK: 'click_aggregation_marker',
            MAP_MOVEMENT_ENABLED: 'click_map_movement_on',
            MAP_MOVEMENT_DISABLED: 'click_map_movement_off',
            UPDATE_PINS: 'click_update_pins_in_area',
            NAVIGATE_MOBILE_MAPSEARCH_SLIDER: 'navigate_mobile_mapsearch_slider',
        },
    },
    tileViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    filterSection: {
        filterClick: {
            priceFilter: {
                START_PRICE: 'price-#startprice=',
                END_PRICE: '_#endprice=',
            },
            cancellationPolicyFilter: {
                START_LABEL: 'freecancel-',
            },
        },
        filterState: {
            CHECK: 'check',
            UNCHECK: 'uncheck',
        },
    },
}
