import { DataLayerEventPayload, EventPayload } from './types'

export function transformEventPayload(eventPayload: EventPayload): DataLayerEventPayload {
    const event: DataLayerEventPayload = {}

    // Add predefined keys to event.
    if (eventPayload.action) {
        event.ga_eventAction = eventPayload.action
        delete eventPayload.action
    }
    if (eventPayload.category) {
        event.ga_eventCategory = eventPayload.category
        delete eventPayload.category
    }
    if (eventPayload.label) {
        event.ga_eventLabel = eventPayload.label
        delete eventPayload.label
    }
    if (eventPayload.value) {
        event.ga_eventValue = eventPayload.value
        delete eventPayload.value
    }

    // Add remaining keys to event.
    Object.entries(eventPayload).forEach(([key, value]) => (event[key] = value))

    return event
}
