import React, { forwardRef, PropsWithChildren, useContext } from 'react'
import { GhostButton as Button, GhostButtonProps } from 'react-components'
import { ThemeContext } from 'styled-components'

type Props = GhostButtonProps

const GhostButton = forwardRef<any, PropsWithChildren<Props>>(({ children, ...props }, ref) => {
    const theme = useContext(ThemeContext)
    return (
        <Button color={theme.colors.tfwBlack87} {...props} ref={ref}>
            {children}
        </Button>
    )
})

GhostButton.displayName = 'GhostButton'

export default GhostButton
